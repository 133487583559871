import { State } from 'history';
import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import {
  Row, Col, Text, Icon,
} from '@swift-paypal/pp-react';
import MarketingPreferences from '../MarketingPreferences/MarketingPreferences';
import { MarketingApi, Configuration } from '../../codegen/mooapi';
import AppConfigContext from '../../util/appConfig';
import getUrlParam from '../../util/getUrlParam';
import UrlSearchParamKey from '../../constants/urlSearchParamKey';
import './account-section.scss';
import routes from '../../routes/routes';
import RouteName from '../../routes/routeName';

const AccountSection: React.FC = () => {
  const { apiUrl } = useContext(AppConfigContext);

  const history = useHistory();
  const location = useLocation<State>();
  const crmId = getUrlParam<string>(location, UrlSearchParamKey.crmId);

  const [email, setEmail] = useState('');
  const [receiveMarketing, setReceiveMarketing] = useState(false);

  const handleApiError = () => {
    history.push(routes[RouteName.NotFound].path);
  };

  useEffect(() => {
    if (!crmId) {
      return;
    }

    const fetchPreferences = async () => {
      const apiConfig = new Configuration({ basePath: apiUrl });
      const api = new MarketingApi(apiConfig);

      try {
        const response = await api.subscriptionsControllerGetSubscriptions(crmId);
        if (response.status >= 300) {
          handleApiError();
          return;
        }

        setEmail(response.data.email);
        setReceiveMarketing(!response.data.hasOptedOutOfEmail);
      } catch {
        handleApiError();
      }
    };

    fetchPreferences();
  }, [apiUrl, crmId]);

  const saveChanges = async () => {
    const apiConfig = new Configuration({ basePath: apiUrl });
    const api = new MarketingApi(apiConfig);

    if (!crmId) {
      return;
    }

    try {
      const response = await api.subscriptionsControllerPatchSubscriptions(crmId, {
        hasOptedOutOfEmail: !receiveMarketing,
      });

      if (response.status >= 300) {
        handleApiError();
        return;
      }
    } catch {
      handleApiError();
    }
  };

  if (!crmId) {
    return <Redirect to={routes[RouteName.NotFound].path} />;
  }

  return (
    <>
      {email && (
        <Row className="email-row">
          <Col>
            <Text className="email" regular>
              <Icon className="email-icon" name="email" as="span" />
              {email}
            </Text>
          </Col>
        </Row>
      )}
      <Row className="marketing-preferences-row">
        <Col md={4}>
          <Text className="head" regular as="h2" size="xl2">Marketing Preferences</Text>
        </Col>
        <Col md={8}>
          <MarketingPreferences
            receiveMarketing={receiveMarketing}
            setReceiveMarketing={setReceiveMarketing}
            saveChanges={saveChanges}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountSection;
