import React from 'react';
import './header.scss';
import { Button, Link } from '@swift-paypal/pp-react';

const Header : React.FC = () => (
  <div className="header">
    <Link className="logo-link" href="https://www.paypal.com">
      <img
        className="logo"
        src="https://www.paypalobjects.com/digitalassets/c/paypal-ui/logos/svg/paypal-mark-monotone-transparent.svg"
        alt="PayPal Logo"
      />
    </Link>
    <div className="gap" />
    <Button className="log-in" secondary inverse as="a" href="https://www.paypal.com/signin">
      Log In
    </Button>
    <Button className="sign-up" inverse as="a" href="https://www.paypal.com/webapps/mpp/account-selection ">
      Sign Up
    </Button>
  </div>
);

export default Header;
