import React from 'react';
import {
  Row, Col, Text, Link,
} from '@swift-paypal/pp-react';
import './not-found.scss';

const NotFound: React.FC = () => (
  <Row className="not-found">
    <Col>
      <Text as="h1" size="xl" medium>Page Not Found</Text>
      <Text>We&apos;re sorry. We couldn&apos;t find the page that you were looking for.</Text>
      <Text>
        Visit
        {' '}
        <Link href="https://www.paypal.com">
          www.paypal.com
        </Link>
      </Text>
    </Col>
  </Row>
);

export default NotFound;
