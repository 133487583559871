import React from 'react';
import './marketing-preferences.scss';
import { Divider, Button, Text } from '@swift-paypal/pp-react';
import PreferenceEntry from './PreferenceEntry/PreferenceEntry';

type MarketingPreferencesProps = {
  receiveMarketing: boolean;
  setReceiveMarketing: (value: boolean) => void;
  saveChanges: () => void;
};

const MarketingPreferences: React.FC<MarketingPreferencesProps> = ({
  receiveMarketing,
  setReceiveMarketing,
  saveChanges,
}) => (
  <div className="marketing-preferences">
    <Text regular className="header-name">You&apos;re Receiving</Text>
    <Text regular className="header-checkbox">E-Mail</Text>
    <Divider className="divider top" />
    <PreferenceEntry
      name="News & Promotions"
      description="We'll send important info about our products and benefits to help you get the most from your account"
      value={receiveMarketing}
      setValue={setReceiveMarketing}
    />
    <Divider className="divider bottom" secondary />
    <Button className="save-button" onClick={saveChanges}>
      Save
    </Button>
  </div>
);

export default MarketingPreferences;
