import React from 'react';
import { paramCase } from 'change-case';
import { Checkbox, Text } from '@swift-paypal/pp-react';

type PreferenceEntryProps = {
  name: string;
  description: string;
  value: boolean;
  setValue: (value: boolean) => void;
};

const PreferenceEntry: React.FC<PreferenceEntryProps> = ({
  name,
  description,
  value,
  setValue,
}) => {
  const idName = paramCase(name);
  const labelId = `${idName}-label`;
  const descriptionId = `${idName}-description`;

  return (
    <>
      <Text regular id={labelId} className="entry-name">{name}</Text>
      <Checkbox
        name={idName}
        checked={value}
        onChange={(e) => setValue(e.target.checked)}
        className="entry-checkbox"
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
      />
      <Text id={descriptionId} className="entry-description">{description}</Text>
    </>
  );
};

export default PreferenceEntry;
