import React from 'react';
import DefaultLayout from '../layouts/default/Default';
import NotFound from '../components/NotFound/NotFound';
import PageComponentName from './pageComponentName';

const NotFoundPage: React.FC = () => (
  <DefaultLayout elements={[
    <NotFound key="NotFound" />,
  ]}
  />
);

NotFoundPage.displayName = PageComponentName.NotFound;

export default NotFoundPage;
