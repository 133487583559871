import React from 'react';
import { Divider } from '@swift-paypal/pp-react';
import DefaultLayout from '../layouts/default/Default';
import AccountSection from '../components/AccountSection/AccountSection';
import InterestBasedAdvertisingSection from '../components/InterestBasedAdvertising/InterestBasedAdvertising';
import PageComponentName from './pageComponentName';

const PreferencesPage: React.FC = () => (
  <DefaultLayout elements={[
    <AccountSection key="AccountSection" />,
    <Divider key="Divider" />,
    <InterestBasedAdvertisingSection key="InterestBasedAdvertisingSection" />,
  ]}
  />
);

PreferencesPage.displayName = PageComponentName.Preferences;

export default PreferencesPage;
