import React from 'react';
import {
  Row, Col, Text, Link,
} from '@swift-paypal/pp-react';
import './interest-based-advertising.scss';

const InterestBasedAdvertisingSection: React.FC = () => (
  <Row className="interest-based-advertising">
    <Col>
      <div className="content-container">
        <Text as="h2" size="xl2" regular>Interest-based advertising</Text>
        <Text size="md">
          We partner with certain third-party advertising providers to show you
          ads you&apos;ll find more relevant. If you don&apos;t want to see
          customized ads, you can opt-out of interest-based advertising by
          companies participating in the Digital Advertising Alliance
          (&quot;DAA&quot;) at the DAA&apos;s Choice Page, located at
          {' '}
          <Link href="http://www.aboutads.info/choices">
            http://www.aboutads.info/choices
          </Link>
          . You&apos;ll still see ads, but they
          won&apos;t be tailored to your interests.
        </Text>
      </div>
    </Col>
  </Row>
);

export default InterestBasedAdvertisingSection;
