import axios from 'axios';

export default function initializeSpinner(setLoading: (loading: boolean) => void) {
  let pendingRequests: number = 0;

  const updateLoading = () => {
    setLoading(pendingRequests > 0);
  };

  axios.interceptors.request.use((config) => {
    pendingRequests += 1;
    updateLoading();
    return config;
  }, (error) => Promise.reject(error));

  axios.interceptors.response.use((response) => {
    pendingRequests -= 1;
    updateLoading();
    return response;
  }, (error) => {
    pendingRequests -= 1;
    updateLoading();
    return Promise.reject(error);
  });
}
