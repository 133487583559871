import 'core-js';
import 'core-js/stage/3';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppConfig } from './util/appConfig';

async function loadConfigAndStart() {
  const response = await axios.get(`${process.env.PUBLIC_URL}/config.json`);
  const config = response.data as AppConfig;

  ReactDOM.render(
    <App config={config} />,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

loadConfigAndStart();
