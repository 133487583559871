/* tslint:disable */
/* eslint-disable */
/**
 * Marketing Opt Out
 * API for allowing users to opt out of marketing emails
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ContactPreference
 */
export interface ContactPreference {
    /**
     * Create contact preference objcet in Salesforce to let users opt out of debt collection emails
     * @type {string}
     * @memberof ContactPreference
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPreference
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPreference
     */
    ownerId?: string;
}
/**
 * 
 * @export
 * @interface InformationResponse
 */
export interface InformationResponse {
    /**
     * A message describing the outcome of a request
     * @type {string}
     * @memberof InformationResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface SubscriptionChanges
 */
export interface SubscriptionChanges {
    /**
     * Whether or not the requested user has opted out of marketing emails
     * @type {boolean}
     * @memberof SubscriptionChanges
     */
    hasOptedOutOfEmail?: boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionStatus
 */
export interface SubscriptionStatus {
    /**
     * The email address of the requested user
     * @type {string}
     * @memberof SubscriptionStatus
     */
    email: string;
    /**
     * Whether or not the requested user has opted out of marketing emails
     * @type {boolean}
     * @memberof SubscriptionStatus
     */
    hasOptedOutOfEmail: boolean;
}

/**
 * MarketingApi - axios parameter creator
 * @export
 */
export const MarketingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerRedirectToDocs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint creates a new Contact Preference object in Salesforce
         * @param {ContactPreference} contactPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactPreferenceControllerCreateContactPreference: async (contactPreference: ContactPreference, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactPreference' is not null or undefined
            if (contactPreference === null || contactPreference === undefined) {
                throw new RequiredError('contactPreference','Required parameter contactPreference was null or undefined when calling contactPreferenceControllerCreateContactPreference.');
            }
            const localVarPath = `/contact_preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof contactPreference !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contactPreference !== undefined ? contactPreference : {}) : (contactPreference || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the current subscription status of a user associated with the given CRM ID
         * @param {string} crmId A valid ID for a lead or contact in the CRM system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetSubscriptions: async (crmId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmId' is not null or undefined
            if (crmId === null || crmId === undefined) {
                throw new RequiredError('crmId','Required parameter crmId was null or undefined when calling subscriptionsControllerGetSubscriptions.');
            }
            const localVarPath = `/subscriptions/{crmId}`
                .replace(`{${"crmId"}}`, encodeURIComponent(String(crmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the subscription status of a suer associated with the given CRM ID
         * @param {string} crmId A valid ID for a lead or contact in the CRM system
         * @param {SubscriptionChanges} subscriptionChanges 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerPatchSubscriptions: async (crmId: string, subscriptionChanges: SubscriptionChanges, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmId' is not null or undefined
            if (crmId === null || crmId === undefined) {
                throw new RequiredError('crmId','Required parameter crmId was null or undefined when calling subscriptionsControllerPatchSubscriptions.');
            }
            // verify required parameter 'subscriptionChanges' is not null or undefined
            if (subscriptionChanges === null || subscriptionChanges === undefined) {
                throw new RequiredError('subscriptionChanges','Required parameter subscriptionChanges was null or undefined when calling subscriptionsControllerPatchSubscriptions.');
            }
            const localVarPath = `/subscriptions/{crmId}`
                .replace(`{${"crmId"}}`, encodeURIComponent(String(crmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof subscriptionChanges !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(subscriptionChanges !== undefined ? subscriptionChanges : {}) : (subscriptionChanges || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingApi - functional programming interface
 * @export
 */
export const MarketingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerRedirectToDocs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MarketingApiAxiosParamCreator(configuration).appControllerRedirectToDocs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint creates a new Contact Preference object in Salesforce
         * @param {ContactPreference} contactPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactPreferenceControllerCreateContactPreference(contactPreference: ContactPreference, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MarketingApiAxiosParamCreator(configuration).contactPreferenceControllerCreateContactPreference(contactPreference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the current subscription status of a user associated with the given CRM ID
         * @param {string} crmId A valid ID for a lead or contact in the CRM system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerGetSubscriptions(crmId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionStatus>> {
            const localVarAxiosArgs = await MarketingApiAxiosParamCreator(configuration).subscriptionsControllerGetSubscriptions(crmId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates the subscription status of a suer associated with the given CRM ID
         * @param {string} crmId A valid ID for a lead or contact in the CRM system
         * @param {SubscriptionChanges} subscriptionChanges 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerPatchSubscriptions(crmId: string, subscriptionChanges: SubscriptionChanges, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MarketingApiAxiosParamCreator(configuration).subscriptionsControllerPatchSubscriptions(crmId, subscriptionChanges, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MarketingApi - factory interface
 * @export
 */
export const MarketingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerRedirectToDocs(options?: any): AxiosPromise<void> {
            return MarketingApiFp(configuration).appControllerRedirectToDocs(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint creates a new Contact Preference object in Salesforce
         * @param {ContactPreference} contactPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactPreferenceControllerCreateContactPreference(contactPreference: ContactPreference, options?: any): AxiosPromise<void> {
            return MarketingApiFp(configuration).contactPreferenceControllerCreateContactPreference(contactPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the current subscription status of a user associated with the given CRM ID
         * @param {string} crmId A valid ID for a lead or contact in the CRM system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetSubscriptions(crmId: string, options?: any): AxiosPromise<SubscriptionStatus> {
            return MarketingApiFp(configuration).subscriptionsControllerGetSubscriptions(crmId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the subscription status of a suer associated with the given CRM ID
         * @param {string} crmId A valid ID for a lead or contact in the CRM system
         * @param {SubscriptionChanges} subscriptionChanges 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerPatchSubscriptions(crmId: string, subscriptionChanges: SubscriptionChanges, options?: any): AxiosPromise<void> {
            return MarketingApiFp(configuration).subscriptionsControllerPatchSubscriptions(crmId, subscriptionChanges, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketingApi - object-oriented interface
 * @export
 * @class MarketingApi
 * @extends {BaseAPI}
 */
export class MarketingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public appControllerRedirectToDocs(options?: any) {
        return MarketingApiFp(this.configuration).appControllerRedirectToDocs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint creates a new Contact Preference object in Salesforce
     * @param {ContactPreference} contactPreference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public contactPreferenceControllerCreateContactPreference(contactPreference: ContactPreference, options?: any) {
        return MarketingApiFp(this.configuration).contactPreferenceControllerCreateContactPreference(contactPreference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the current subscription status of a user associated with the given CRM ID
     * @param {string} crmId A valid ID for a lead or contact in the CRM system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public subscriptionsControllerGetSubscriptions(crmId: string, options?: any) {
        return MarketingApiFp(this.configuration).subscriptionsControllerGetSubscriptions(crmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the subscription status of a suer associated with the given CRM ID
     * @param {string} crmId A valid ID for a lead or contact in the CRM system
     * @param {SubscriptionChanges} subscriptionChanges 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public subscriptionsControllerPatchSubscriptions(crmId: string, subscriptionChanges: SubscriptionChanges, options?: any) {
        return MarketingApiFp(this.configuration).subscriptionsControllerPatchSubscriptions(crmId, subscriptionChanges, options).then((request) => request(this.axios, this.basePath));
    }
}


