import React from 'react';
import { Route } from 'react-router-dom';

import routes from './routes';
import { getInnerDisplayName } from '../util/getDisplayName';

import HomeRedirectPage from '../pages/HomeRedirect';
import NotFoundPage from '../pages/NotFound';
import PreferencesPage from '../pages/Preferences';
import UnsubscribePage from '../pages/Unsubscribe';

const components: React.ComponentType[] = [
  HomeRedirectPage,
  NotFoundPage,
  PreferencesPage,
  UnsubscribePage,
];

interface ComponentMap {
  [name: string]: React.ComponentType;
}

const componentsByName = components.reduce((map, component) => {
  const name = getInnerDisplayName(component);
  const newMap = { ...map };
  newMap[name] = component;
  return newMap;
}, {} as ComponentMap);

export default function getRoutes() {
  return Object.values(routes).map((route) => {
    const component = componentsByName[route.componentName];
    if (!component) {
      throw new Error(`Could not find component ${route.componentName} when mapping ${route.path}.`);
    }
    return (
      <Route
        exact
        strict
        key={route.path}
        path={route.path}
        component={component}
      />
    );
  });
}
