import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

const TrailingSlashRedirect: React.FC = () => {
  const location = useLocation();
  const correctedRoute = {
    pathname: location.pathname.substring(0, location.pathname.length - 1),
    search: location.search,
    state: location.state,
  };
  return <Redirect to={correctedRoute} />;
};

export default TrailingSlashRedirect;
