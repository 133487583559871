import React from 'react';
import DefaultLayout from '../layouts/default/Default';
import PageComponentName from './pageComponentName';
import CollectionEmail from '../components/CollectionEmail/CollectionEmail';

const UnsubscribePage: React.FC = () => (
  <DefaultLayout elements={[
    <CollectionEmail key="collection-email" />,
  ]}
  />
);

UnsubscribePage.displayName = PageComponentName.Unsubscribe;

export default UnsubscribePage;
