import React from 'react';
import { LoadingSpinner } from '@swift-paypal/pp-react';
import './loading-overlay.scss';

type LoadingOverlayProps = {
  show: boolean;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ show }) => {
  if (!show) {
    return <></>;
  }

  return (
    <div className="loading-overlay">
      <LoadingSpinner className="spinner" screenReaderText="loading" size="lg" />
    </div>
  );
};

export default LoadingOverlay;
