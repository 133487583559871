import React from 'react';

export interface AppConfig {
  apiUrl: string;
  version: string;
}

const AppConfigContext = React.createContext<AppConfig>({
  apiUrl: 'http://localhost:4030',
  version: 'v0.0.0-0',
});

export default AppConfigContext;
