import React from 'react';
import { Container } from '@swift-paypal/pp-react';
import Header from '../../components/Header/Header';
import './default.scss';

type DefaultLayoutProps = {
  elements: JSX.Element[];
};

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ elements }) => (
  <>
    <Header />
    <Container className="page-body">
      {elements}
    </Container>
  </>
);

export default React.memo(DefaultLayout);
