import React, { useState, useEffect } from 'react';
import {
  Switch, BrowserRouter as Router, Route, Redirect,
} from 'react-router-dom';
import './App.scss';
import AppConfigContext, { AppConfig } from './util/appConfig';
import TrailingSlashRedirect from './components/TrailingSlashRedirect';
import getRoutes from './routes/routesFactory';
import ScrollToTop from './components/ScrollToTop';
import routes from './routes/routes';
import initializeSpinner from './util/initializeSpinner';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import RouteName from './routes/routeName';

type AppProps = {
  config: AppConfig;
};

const App: React.FC<AppProps> = ({ config }) => {
  const [loading, setLoading] = useState(false);
  const [spinnerInitialized, setSpinnerInitialized] = useState(false);

  useEffect(() => {
    initializeSpinner(setLoading);
    setSpinnerInitialized(true);
  }, []);

  if (!spinnerInitialized) {
    return <LoadingOverlay show />;
  }

  return (
    <AppConfigContext.Provider value={config}>
      <LoadingOverlay show={loading} />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact strict path="(.+)/" component={TrailingSlashRedirect} />
          {getRoutes()}
          <Redirect from="/" to={routes[RouteName.NotFound].path} />
        </Switch>
      </Router>
    </AppConfigContext.Provider>
  );
};

export default App;
