import React from 'react';
import { Redirect } from 'react-router-dom';
import { redirect } from '../util/redirect';
import PageComponentName from './pageComponentName';
import routes from '../routes/routes';

const HomeRedirectPage: React.FC = () => {
  if (process.env.NODE_ENV === 'development') {
    return <Redirect to={routes.preferences.path} />;
  }

  redirect('https://www.paypal.com/');

  return <></>;
};

HomeRedirectPage.displayName = PageComponentName.HomeRedirect;

export default HomeRedirectPage;
