import PageComponentName from '../pages/pageComponentName';
import RouteName from './routeName';

type RouteTableEntry = {
  path: string;
  componentName: PageComponentName;
};

type RouteTable = {
  [name in RouteName]: RouteTableEntry;
};

/**
 * Don't alphabetize these. React router evaluates these from top to bottom, so it's important to
 * keep the simpler URLs at the top of this file so that they don't get misinterpreted.
 */
const routes: RouteTable = {
  'home-redirect': { path: '/', componentName: PageComponentName.HomeRedirect },
  'not-found': { path: '/not-found', componentName: PageComponentName.NotFound },
  preferences: { path: '/preferences', componentName: PageComponentName.Preferences },
  unsubscribe: { path: '/unsubscribe', componentName: PageComponentName.Unsubscribe },
};

export default routes;
