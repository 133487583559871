import React, { useContext, useState } from 'react';
import {
  Text, Container, Row, Col, TextInput, Divider, Button, Alert,
} from '@swift-paypal/pp-react';

import isEmailValid from '../../util/emailValidator';
import AppConfigContext from '../../util/appConfig';

import { MarketingApi, Configuration, ContactPreference } from '../../codegen/mooapi';

import './collection-email.scss';

const EMAIL_INPUT_TITLE = 'Collection Email Opt Out';
const EMAIL_INPUT_DESCRIPTION = 'Enter the email address below that you would like to opt out of receiving debt collection emails for PayPal Business Loan and LoanBuilder.';
const EVENT = 'DNC';

const CollectionEmail: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailSubmitted, setEmailSubmitted] = useState(false);
  const [hasApiFailed, setApiFailed] = useState(false);

  const { apiUrl } = useContext(AppConfigContext);

  const onChangeEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const createContactPreference = async (emailAddress: string) => {
    const apiConfig = new Configuration({ basePath: apiUrl });
    const api = new MarketingApi(apiConfig);

    try {
      const contactPreference: ContactPreference = {
        email: emailAddress,
        event: EVENT,
      };

      // eslint-disable-next-line max-len
      const { status } = await api.contactPreferenceControllerCreateContactPreference(contactPreference);

      if (status >= 300) {
        setApiFailed(true);
        return;
      }

      setEmailSubmitted(true);
    } catch {
      setApiFailed(true);
    }
  };

  const onSubmitEmailAddress = () => {
    if (email.length > 0) {
      if (isEmailValid(email)) {
        setEmail('');
        setErrorMessage('');
        setApiFailed(false);

        createContactPreference(email);

        return;
      }

      setErrorMessage('Please enter a valid Email Address');
      return;
    }

    setErrorMessage('Please enter an Email Address');
  };

  return (

    <div className="collection-email-container">
      <Container>
        <Row>
          <Col sm={12} md={5}>
            <Text size="xl2" as="h1" medium>{EMAIL_INPUT_TITLE}</Text>
          </Col>

          <Col sm={12} md={7}>
            <div className="collection-input-container">
              <Text size="lg" as="p">{EMAIL_INPUT_DESCRIPTION}</Text>
              <TextInput
                className="collection-email-input"
                name="email_id"
                value={email}
                errorText={errorMessage}
                label="Email address"
                theme="v2"
                onChange={onChangeEmailAddress}
              />
              <Divider secondary />
              {!isEmailSubmitted ? (
                <Button
                  className="collection-input-submit"
                  theme="v2"
                  onClick={onSubmitEmailAddress}
                >
                  Save
                </Button>
              ) : (
                <Alert
                  className="collection-success-alert"
                  type="success"
                >
                  Your request has been submitted
                </Alert>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Divider key="Divider" />

      {hasApiFailed && (
      <Alert
        type="error"
      >
        Something went wrong!
      </Alert>
      )}
    </div>
  );
};

export default CollectionEmail;
